import React, { FC, ReactNode } from 'react';
import { useSession, signIn } from 'next-auth/react';
import { useRouter } from 'next/router';
import 'antd/dist/reset.css';
import { useFetch } from '@/libs/fe/hooks';
import { MenuItemType } from '@/types';
import { MenuListVO } from '@/pages/api/menu/types';
import { GrantedTenantListVO } from '@/pages/api/tenant/types';

const Auth: FC<{
  children: ReactNode;
}> = ({ children }) => {
  const router = useRouter();

  const { data: session, status } = useSession();

  if (status === 'loading') {
    return <h3 style={{ margin: 64 }}>用户登录中，请稍后。。。</h3>;
  } else if (status === 'unauthenticated' && !['/'].includes(router.pathname)) {
    signIn();
    return <h3 style={{ margin: 64 }}>用户尚未登录，跳转到登录页面。。。</h3>;
  }
  return <>{children}</>;
};

export default Auth;
