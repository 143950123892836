export const initMonitor = async () => {
  if (typeof window !== 'undefined') {
    if (window.__bl) {
      return;
    }
    const BrowserLogger = require('@arms/js-sdk');

    const env = await fetch('/api/env/getArmsEnv', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(res => res.json())
      .then(({ data }) => {
        return data;
      });

    const __bl = BrowserLogger.singleton({
      pid: 'jj89umtz4s@ec651698f888f9d',
      appType: 'web',
      imgUrl: 'https://arms-retcode.aliyuncs.com/r.png?',
      sendResource: true,
      enableLinkTrace: true,
      behavior: true,
      environment: env || 'daily'
    });
    console.log('env: ', env);
    window.__bl = __bl;
  }
};
