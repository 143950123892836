import type { AppProps } from 'next/app';
import { useEffect, type ErrorInfo } from 'react';
import { SessionProvider } from 'next-auth/react';
import App from 'next/app';
import Auth from '@/components/Auth';
import { initMonitor } from '@/libs/fe/monitor';

import '@/styles/globals.css';
import Head from 'next/head';

interface MyAppState {
  hasError: boolean;
  error: Error | null;
}

class MyApp extends App<AppProps, MyAppState> {
  constructor(props: AppProps) {
    super(props);
    this.state = { hasError: false, error: null };
  }

  static getDerivedStateFromError(error: Error) {
    // 更新 state，以便下一个渲染将会显示降级的 UI
    return { hasError: true, error: error };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // 可以将错误日志发送到服务器
    console.error(`App caught an error: ${error}, ${errorInfo}`);
  }

  render() {
    const { Component, pageProps, router } = this.props;
    const { hasError, error } = this.state as { hasError: boolean; error: Error };

    if (hasError) {
      // 渲染任何自定义的错误 UI
      return (
        <div style={{ padding: 36 }}>
          <h1>我们遇到了一些问题，请稍后再试</h1>
          <h2>如果问题依然存在，请将此屏截图，提供给管理员寻求帮助</h2>
          <pre>{error?.stack}</pre>
        </div>
      );
    }
    initMonitor();

    return (
      <>
        {router.asPath.includes('debug=true') ? (
          <Head>
            <script
              dangerouslySetInnerHTML={{
                __html: `
            (function(c,l,a,r,i,t,y){
                c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
                t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
                y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
            })(window, document, "clarity", "script", "m44b6qnbqm");
          `
              }}
            />
          </Head>
        ) : null}
        <SessionProvider session={pageProps.session}>
          {router.asPath.startsWith('/workspace') ? (
            <Auth>
              <Component {...pageProps} />
            </Auth>
          ) : (
            <Component {...pageProps} />
          )}
        </SessionProvider>
      </>
    );
  }
}

export default MyApp;
